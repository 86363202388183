import { render, staticRenderFns } from "./Choice.vue?vue&type=template&id=5f110aa8&scoped=true&"
import script from "./Choice.vue?vue&type=script&lang=js&"
export * from "./Choice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f110aa8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Checkbox: function () { return import('/home/runner/work/fjor-wellness-funnel/fjor-wellness-funnel/components/_atoms/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(function(m) { return m['default'] || m }) },Button: function () { return import('/home/runner/work/fjor-wellness-funnel/fjor-wellness-funnel/components/_atoms/Button.vue' /* webpackChunkName: "components/button" */).then(function(m) { return m['default'] || m }) }})
