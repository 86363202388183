export default {
  props: {
    options: {
      type: Object,
      required: true,
      validator(value) {
        return value.hasOwnProperty('items') && Array.isArray(value.items)
      }
    }
  }
}
