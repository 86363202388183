export default {
  methods: {
    textParser(text, values, default_values = {}) {
      const regexp = /\${([^{]+)}/g

      return text.replace(regexp, function (ignore, key) {
        return values && Object.keys(values).includes(key) && values[key]
          ? `${values[key]}`
          : default_values[key] || key
      })
    },
    parseDataValues(dataObj, values) {
      return Object.entries(dataObj).reduce((prev, [id, value]) => {
        const { text, text_parser, default_values } = dataObj[id]

        if (text_parser) {
          prev[id] = this.textParser(text, values, default_values)
        } else {
          prev[id] = text
        }

        return prev
      }, {})
    }
  }
}
