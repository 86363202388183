
import Screen from '@/components/_mixins/Screen'
import ChoiceMixin from '@/components/_mixins/Choice'

export default {
  name: 'ChoiceBlock',
  mixins: [Screen, ChoiceMixin],
  data() {
    return {
      choice: {
        value: ''
      }
    }
  },
  computed: {
    currentNextPageUrl() {
      console.log(this.choice.value)
      return this.nextPageUrl
    }
  },
  methods: {
    async onChange(value) {
      this.choice.value = value

      this.$logEvent({
        amp: {
          type: 'Funnel Screen Completed',
          properties: {
            screen_id: this.screen_id,
            funnel_id: this.$store.getters['funnel/version'],
            option: value
          },
          user_properties: { [this.screen_id]: value }
        }
      })

      this.$store.dispatch('funnel/setSteps', {
        [this.screen_id]: value
      })
      await this.$nextTick()

      const item = this.options.items.find(item => item.value === value)
      let url

      if (item?.nextPageId) {
        url = `/s/${item.nextPageId}`
        await this.$router.push({
          path: url,
          query: {
            ...this.$route.query
          }
        })
        return
      }

      if (!this.cta_button) await this.nextPage(url)
    }
  }
}
