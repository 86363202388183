import TextParser from '@/components/_mixins/TextParser'

export default {
  mixins: [TextParser],
  props: {
    screen_id: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    component_id: {
      type: [String, Boolean],
      required: false,
      default: false
    },
    cta_button: {
      type: [Object, Boolean],
      default: false
    },
    options: {
      type: [Object, Boolean],
      default: false
    },
    nextPageUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    async nextPage(data = {}) {
      const current_url = data.url || this.nextPageUrl

      if (process.client && current_url.includes(this.$config.paywall_url)) {
        window.location.href = current_url
        return 0
      }

      await this.$router.push({
        path: current_url,
        query: {
          ...this.$route.query,
          ...data
        }
      })
    }
  }
}
